import {
	isRouteErrorResponse,
	Link,
	useLocation,
	useParams,
	useRouteError,
	type ErrorResponse,
} from "@remix-run/react"

import { Icon } from "@sagaware/ui-icons/Icon"
import { getErrorMessage } from "@sagaware/utils"

// import { captureRemixErrorBoundaryError } from '@sentry/remix'

type StatusHandler = (info: {
	error: ErrorResponse
	params: Record<string, string | undefined>
}) => JSX.Element | null

export function GeneralErrorBoundary({
	defaultStatusHandler = ({ error }) => (
		<p>
			{error.status} {error.data}
		</p>
	),
	statusHandlers,
	unexpectedErrorHandler = (error) => <p>{getErrorMessage(error)}</p>,
}: {
	defaultStatusHandler?: StatusHandler
	statusHandlers?: Record<number, StatusHandler>
	unexpectedErrorHandler?: (error: unknown) => JSX.Element | null
}) {
	const error = useRouteError()
	// captureRemixErrorBoundaryError(error);
	const params = useParams()

	if (typeof document !== "undefined") {
		console.error(error)
	}

	return (
		<div className="container flex items-center justify-center p-20 text-h2">
			{isRouteErrorResponse(error)
				? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
						error,
						params,
					})
				: unexpectedErrorHandler(error)}
		</div>
	)
}

export function MyErrorBoundary() {
	const location = useLocation()
	return (
		<GeneralErrorBoundary
			statusHandlers={{
				404: () => (
					<div className="flex flex-col gap-6">
						<div className="flex flex-col gap-3">
							<h1>We can't find this page:</h1>
							<pre className="whitespace-pre-wrap break-all text-body-lg">
								{location.pathname}
							</pre>
						</div>
						<Link to="/" className="flex items-center text-body-md underline">
							<Icon
								name="ArrowLeft"
								className="mr-2 size-5 text-muted-foreground/70"
							/>
							Back to home
						</Link>
					</div>
				),
			}}
		/>
	)
}
