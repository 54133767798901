/**
 * Returns the error message from the given error object.
 *
 * @param {unknown} error - The error object to extract the message from.
 * @return {string} The error message or "Unknown Error" if the message cannot be extracted.
 */
export function getErrorMessage(error: unknown): string {
	if (typeof error === "string") return error
	if (
		error &&
		typeof error === "object" &&
		"message" in error &&
		typeof error.message === "string"
	) {
		return error.message
	}
	console.error("Unable to get error message for error", error)
	return "Unknown Error"
}
